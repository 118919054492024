import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Table } from '@mantine/core';
import { isNaN, isNumber, map, sum } from 'lodash';
import { useCallback } from 'react';
/**
 * @example
 *   return (
 *     <Fragment>
 *       <DataList
 *         data={state.overviews}
 *         columns={[
 *           {
 *             width: 100,
 *             renderHeaderCell: () => '策略名稱', // 支持 ComponentType
 *             renderHeaderCell: '策略名稱', // 支持 string
 *             renderCell: datum => <Fragment>{datum.value.userAccount}</Fragment>,
 *           },
 *           {
 *             renderHeaderCell: () => '編輯', // 支持 ComponentType
 *             renderHeaderCell: '編輯', // 支持 string
 *             renderCell: datum => (
 *               <Button
 *                 size='xs'
 *                 onClick={event => {
 *                   console.log(datum)
 *                 }}
 *               >
 *                 編輯
 *               </Button>
 *             ),
 *           },
 *         ]}
 *       />
 *       <MantinePagination {...state.pagination} />
 *     </Fragment>
 *   )
 */
export function DataList(props) {
    const THs = useCallback(() => {
        return props.columns.map((column, index) => {
            const widthCSS = isNumber(column.width)
                ? css `
            width: ${column.width}px;
          `
                : css ``;
            return (<th key={index} css={[widthCSS, props.stickyHeader && stickyHeaderBaseCss]}>
          {typeof column.renderHeaderCell === 'string' ? column.renderHeaderCell : null}
          {typeof column.renderHeaderCell === 'function' ? column.renderHeaderCell() : null}
        </th>);
        });
    }, [props.columns, props.stickyHeader]);
    const TRs = useCallback(() => {
        return map(props.data, (datum, datumIndex) => (<Tr key={datumIndex}>
        {props.columns.map((column, index) => (<Td key={index}>{column.renderCell({ value: datum })}</Td>))}
      </Tr>));
    }, [props.columns, props.data]);
    const tableMinWidth = sum(props.columns.map(column => column.width ?? 0));
    const tableMinWidthCss = !isNaN(tableMinWidth) &&
        tableMinWidth !== 0 &&
        css `
      width: 100%;
      min-width: ${tableMinWidth}px;
    `;
    return (<DataList.classes.Root>
      <DataList.classes.Table striped withBorder highlightOnHover fontSize='sm' css={[tableMinWidthCss]}>
        <thead>
          <Tr>
            <THs />
          </Tr>
        </thead>

        <tbody>
          <TRs />
        </tbody>
      </DataList.classes.Table>
    </DataList.classes.Root>);
}
DataList.classes = {
    Root: styled.div `
    width: 100%;
    overflow-x: auto;

    .mantine-Table-root {
      th {
        padding: 4px;
      }
      td {
        padding: 8px;
        font-variant: tabular-nums;
      }
    }
  `,
    Table: styled.div `
    table-layout: fixed;
  `.withComponent(Table),
};
function Tr(props) {
    return <tr>{props.children}</tr>;
}
function Td(props) {
    return (<td css={css `
        word-wrap: break-word;
      `}>
      {props.children}
    </td>);
}
const stickyHeaderBaseCss = css `
  position: sticky;
  top: 0px;
  background: white;
`;
